import React from 'react'
import WRamce from './WRamce'

import PC from '../img/zdjęcia/PCAWv2.png'
import Telefon from '../img/zdjęcia/telefonAWv2.png'
import Tablet from '../img/zdjęcia/tabletAWv2.png'

import DjangoIcon from '../img/ikony/umiejętności/django.svg'
import ReactIcon from '../img/ikony/umiejętności/react.svg'
import RestAPIIcon from '../img/ikony/umiejętności/restAPI.svg'
import RenderIcon from '../img/ikony/narzędzia/render.svg'

const SekcjaAW = () => {
  let linki = ['https://awprojekt.com/', 'https://github.com/BartekG12/AW-projekt']
  let techIcons = [DjangoIcon, ReactIcon, RestAPIIcon, RenderIcon]
  let CzyGithub = false
  return (
    <div className='PolowniczyPojemnik IntersekcjaPrawa'>
        <div className='PolowniczyLewa LewaAW'>
            <div className='Podsekcja'>
            "Studio AW Projekt"
            </div>
            <div className='Tekst KontrolaTekstu'>
            Website created for the company "Studio AW Projekt". <br/>
            I designed and built it completely anew, which led to an increase in the number of
            <b> new users</b> by approximately <b>30%</b> and the number of
            received <b>cooperation proposals</b> by approximately <b>5%</b>.
            The code for this project will remain private, as requested by the client.

            </div> 
            <WRamce linki={linki} techIcons={techIcons} CzyGithub={CzyGithub}/>
        </div>
        <div className='PolowniczyPrawa PrawaAW'>
            <img className='Img PC' src={PC} alt='PC' />
            <img className='Img Telefon' src={Telefon} alt='Telefon' />
            <img className='Img Tablet' src={Tablet} alt='Tablet' />
        </div>
    </div>
  )
}

export default SekcjaAW
