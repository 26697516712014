import WRamce from './WRamce'

import PC from '../img/zdjęcia/PCPrzepisy.png'
import Telefon from '../img/zdjęcia/TelefonPrzepisy.png'
import Tablet from '../img/zdjęcia/TabletPrzepisy.png'

import SQLIcon from '../img/ikony/umiejętności/sql.svg'
import DjangoIcon from '../img/ikony/umiejętności/django.svg'
import RenderIcon from '../img/ikony/narzędzia/render.svg'

const SekcjaPrzepisy = () => {
  let linki = ['https://oryginalne-przepisy.onrender.com/', 'https://github.com/BartekG12/Oryginalne-Przepisy']
  let techIcons = [SQLIcon, DjangoIcon, RenderIcon]
  let CzyGithub = true
  return (
    <div className='PolowniczyPojemnik PolPojOdstep IntersekcjaLewa'>
        <div className='PolowniczyLewa LewaPrzepisy'>
            <img className='Img PC' src={PC} alt='PC' />
            <img className='Img Telefon' src={Telefon} alt='Telefon' />
            <img className='Img Tablet' src={Tablet} alt='Tablet' />
        </div>
        <div className='PolowniczyPrawa PrawaPrzepisy'>
            <div className='Podsekcja'>
            Original recipes
            </div>
            <div className='Tekst KontrolaTekstu'>
            A website promoting the publication of new, original recipes. 
            After creating an account, you can post recipes and 
            request verification of their originality. 
            It allows you to promote your business, participate in rankings, 
            send and receive messages,&nbsp;etc. 
            Provides protection against XSS attacks, SQL injections, CSRF,&nbsp;etc.
            </div>
            <WRamce linki={linki} techIcons={techIcons} CzyGithub={CzyGithub} />
        </div>
    </div>
  )
}

export default SekcjaPrzepisy
