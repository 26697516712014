import React from 'react'

import AGH from '../img/zdjęcia/AGH.png'
import IS from '../img/zdjęcia/IS.jpg'

const SekcjaEdukacja = () => {
    return (
      <div id='Edukacja'>
        <div id='EduLink' className='Lapacz'></div>
        <div className='Sekcja IntersekcjaPrawa' >
            <div className='PrzerywanaSekcji'></div>
            <div className='TytulSekcji'>EDUCATION</div>
            <div className='PrzerywanaSekcji'></div>
        </div>
        <div className='PojemnikEdukacja IntersekcjaPrawa'>
            <div className='PolowniczyLewa LewaAGH'>
                <div className='Podsekcja'>
                AGH University of Science and Technology
                01.02.2019 - 01.02.2022 (Graduated)
                </div>
                <div className='Tekst KontrolaTekstu'>
                  The best technical university in Poland.
                  Focuses on providing practical understanding and experience with the most important
                  and innovative technologies.
                  Aside from mastering individual skills, it also gave me the opportunity to explore 
                  different technologies in practice, complete many group projects, and gain first
                  experiences.
                </div>
            </div>
            <div className='PolowniczyPrawa PrawaAGH'>
                <img className='Img AGH' src={AGH} alt='AGH' />
            </div>
        </div>
  
        <div className='PojemnikEdukacja PojemnikIS PolPojOdstep IntersekcjaLewa'>
          <div className='PolowniczyLewa LewaIS'>
            <img className='Img' src={IS} alt='IS' />
          </div>
          <div className='PolowniczyPrawa PrawaIS'>
              <div className='Podsekcja'>
              "Informatyka społeczna"
              </div>
              <div className='Tekst KontrolaTekstu'> 
                "IT in society" is a field of study that, on top of computer science and psychology knowledge, 
                teaches how to apply technology in society and work in a team.
                With current global changes in technology, this approach is becoming more important than ever.                
                <br/>
              </div>
          </div>
        </div>
      </div>
    )
}

export default SekcjaEdukacja
