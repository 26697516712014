import React from 'react'

import Programista from '../img/zdjęcia/programista.png'

import CssIcon from '../img/ikony/umiejętności/css.svg'
import DjangoIcon from '../img/ikony/umiejętności/django.svg'
import HtmlIcon from '../img/ikony/umiejętności/html.svg'
import JSIcon from '../img/ikony/umiejętności/js.svg'
import PythonIcon from '../img/ikony/umiejętności/python.svg'
import ReactIcon from '../img/ikony/umiejętności/react.svg'
import RestAPIIcon from '../img/ikony/umiejętności/restAPI.svg'
import SQLIcon from '../img/ikony/umiejętności/sql.svg'

import FigmaIcon from '../img/ikony/narzędzia/figma.svg'
import GitIcon from '../img/ikony/narzędzia/git.svg'
import GithubIcon from '../img/ikony/narzędzia/github.svg'
import RenderIcon from '../img/ikony/narzędzia/render.svg'
import VSCIcon from '../img/ikony/narzędzia/VSC.svg'


const Orbity = () => {
    let UmiejetnosciINarzedzia = [CssIcon, DjangoIcon, HtmlIcon, JSIcon, PythonIcon, 
        ReactIcon, RestAPIIcon, SQLIcon, FigmaIcon, GitIcon, GithubIcon, RenderIcon, VSCIcon]
    let UmiejCzyNarz = UmiejetnosciINarzedzia.map( (element, index) => {
        if (index <= 7) {return 'OrbitaUmiejetnosci'}
        else return 'OrbitaNarzedzia'
    })
  return (
    <div className='PolowniczyPojemnik PojemnikOrbit'> 
        <div className='PolowniczyLewa LewaOrbity'>
            <div className='Witam'>
            Welcome to my portfolio website!
            </div>
            <div className='NazywamSie KontrolaTekstu'>
            My name is Bartosz Gurgul, and I am a software engineer. <br/>
            I specialize in full-stack development utilizing cloud services and devops tools. 
            Right now I'm working using mostly React, Node and Azure 
            (javascript, azure&nbsp;devops, cosmos&nbsp;db&nbsp;etc.), 
            but I also have experience with Django (python).
            I&nbsp;currently&nbsp;live in Cracow,&nbsp;Poland.
            </div>
            <div className='Zapraszam'>Feel free to look through some of my projects or reach out</div>
        </div>
        <div className='PolowniczyPrawa PrawaOrbity'>     
            <div className="PojemnikWszystkichOrbit">
                {UmiejetnosciINarzedzia.map((element, index) => {return (
                    <div key={index} className={'n'+String(index)}>
                        <div className={UmiejCzyNarz[index]}>
                            <div className={'Pojem'+UmiejCzyNarz[index]}>
                                <div className="ElementOrbity">
                                    <div className="ElementOrbityWnetrze">
                                        <img src={element} alt={element} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )})}
                <img className='Programista' src={Programista} alt='Programista'/>
            </div>
        </div>   
    </div>
  )
}
export default Orbity
